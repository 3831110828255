<template>
  <div v-wechat-title="$route.meta.title">
    <!-- <van-nav-bar left-arrow @click-left="onClickLeft" :border="false" /> -->
    <van-search
      @input="onClickSarch"
      v-model="value"
      placeholder="请输入课程标题搜索"
      class="search p16"
      left-icon
    >
      <template slot="label" class="seachico">
        <span class="icon-Group-863">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </span>
      </template>
    </van-search>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="onLoad"
    >
      <van-row type="flex" justify="flex-start" gutter="16" class="content-item p16">
        <van-col span="12" v-for="item in gridArr" :key="item.id">
          <router-link :to="'/details/'+item.id">
            <div style="height: 120px; overflow: hidden; border-radius: 10px;">
              <img
                v-if="item.curriculumImage"
                :src="`http://114.116.236.37:9001/${item.curriculumImage}`"
                style="height: 100%;"
              />
              <div
                v-else
                style="text-align: center; line-height: 100px; height: 100%; border: 1px solid #f5e5e58f; width: 100%;"
              >暂无图片</div>
            </div>
            <h4 class="van-ellipsis course-title">{{item.curriculumTitle}}</h4>
            <div class="row-item">
              <span class="teach-title">{{item.curriculumAuthor}}</span>
              <span class="browse-title">
                <van-icon name="eye" color="rgba(51,51,51,36%)" />
                {{item.curriculumViews}}
              </span>
            </div>
          </router-link>
        </van-col>
      </van-row>
    </van-list>
    <van-empty v-show="gridArr.length === 0" description="请输入课程标题搜索" />
    <!-- <div class="mpty-con">请输入内容进行检索</div> -->
    <!-- <van-divider v-show="gridArr.length === 0" class="mpty-con">请输入内容进行检索</van-divider> -->
  </div>
</template>

<script>
import { POST } from "@/common/request";
const api = {
  querySrCurriculum: "/xkcshowApi/show/querySrCurriculum.htm"
};
export default {
  data() {
    return {
      value: "",
      // list: [],
      gridArr: [],
      pageData: {
        page: 1,
        size: 20,
        total: null
      },
      loading: false,
      finished: false,
      timeout: null
    };
  },
  methods: {
    onClickLeft() {
      window.history.go(-1);
    },
    onClickSarch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.gridArr = [];
        this.finished = false;
        this.pageData = {
          page: 1,
          size: 20,
          total: 0
        };
        this.getCurriculum();
      }, 1000);
    },
    getCurriculum() {
      POST(api.querySrCurriculum, {
        curriculumTitle: this.value,
        // orderBys: "updateTime",
        // orderDir: "desc",
        page: this.pageData.page,
        size: this.pageData.size
      })
        .then(res => {
          if (res.code === 200) {
            let rows = res.data; //请求返回当页的列表
            this.loading = false;
            this.pageData.total = res.total;
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.finished = true;
              return;
            }

            // 将新数据与老数据进行合并
            this.gridArr = this.gridArr.concat(rows);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.gridArr.length >= this.pageData.total) {
              this.finished = true;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    onLoad() {
      this.pageData.page++;
      this.getCurriculum();
    }
  }
};
</script>

<style lang="less" scoped>
.search {
  margin-top: 6px;
  .icon-Group-863 {
    display: block;
    height: 20px;
    font-size: 32px;
  }
}
.van-search__content {
  padding: 0;
}
/deep/ .van-icon-arrow-left:before {
  color: #6a6a6a;
}
.my-swipe {
  margin: 11px 0 12px 0;
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  /deep/.van-swipe__indicator {
    width: 10px;
    height: 2px;
    border-radius: 50px;
  }
  /deep/ .van-swipe__indicators {
    position: absolute;
    left: 82%;
  }
}
.content {
  display: flex;
  justify-content: start;
  text-align: center;
  height: 40px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  margin: 8px 0;
}
.svgIcn {
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
}
.content-item {
  margin-top: 8px;
}
.row-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 17px 0;
}
.course-title {
  display: flex;
  justify-content: start;
  // padding: 10px 15px 8px 0;
  margin-top: 12px;
  font-size: 16px;
  color: #30334c;
}
.teach-title {
  // margin: 8px 0 10px 0;
  color: #8989a6;
  font-size: 10px;
}
.browse-title {
  width: 50%;
  height: 20px;
  line-height: 22px;
  color: #8989a6;
  font-size: 10px;
  text-align: right;
}
.mpty-con {
  // color: #8989a6;
  margin-top: 90px;
}
</style>